.App {
  text-align: center;

  display: flex;
  flex-direction: column;
  font-family: 'Inter';
}

.no-margin {
  margin: 0;
}

.padding-sm {
  padding: 20px;
}

.padding-lg {
  padding: 10vw;
}

.display-flex {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

video {
  width: 100%;
}

.hero {
  display: flex;
  
  justify-content: center;

  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.hero-content {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;

  flex-direction: column;

  justify-content: center;
  padding-top: 10vh;
}

.hero-cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 15vh;
  flex: 1;
}

.hero-desc {
  margin: 0 auto;
  font-size: 40px;
  font-family: 'Mynerve', cursive;
  color: #C72A7B;
}

.footer {
  color: #C72A7B;
}

@media screen and (max-width: 640px) {
  .hero-desc {
    font-size: 30px;
  }
}

.button {
  all: unset;
  background-color: #00FF29;
  border: 6px solid black;
  display: inline-block;
  padding: 20px 30px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 24px;
  box-shadow: 10px 10px 0px #C72A7B;
  transition: all 150ms ease-in-out;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .button {
    padding: 16px 20px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  }
}

.button-sub {
  margin: 0;
  font-size: small;
  font-style: italic;
  font-weight: 400;
}

h1 {
  /* font-family: 'Mynerve', sans-serif; */
  font-family: 'Mynerve', cursive;
  font-weight: 800;
  font-size: 4vw;
  color: #FAE3F1;
  margin: 0;
}

h1.lg {
  font-size: 8vw;
}

@media screen and (max-width: 640px) {
  h1.lg {
    font-size: 12vw;
  }
}

h2 {
  font-size: 12vw;
  font-family: 'Mynerve', cursive;
  color: #00FF29;
  margin: 0;
}

@media screen and (max-width: 640px) {
  h2 {
    font-size: 15vw;
  }
}

.font-inter {
  font-family: 'Inter';
}

.font-mynerve {
  font-family: 'Mynerve';
}

.sarcasm {
  margin-top: 8px;
  font-size: 2vw;

}

@media screen and (max-width: 640px) {
  .sarcasm {
    font-size: 4vw;
  }
}

.color-main {
  color: #C72A7B;
}

.text-lg {
  font-size: 7vw;
}

.content-fuschia {
  background: #d9027d;
}

.content-pink {
  background: #FAE3F1;
  min-height: 400px;
  color: #C72A7B;
  display: flex;
  align-items: center;
  padding: 40px;
  position: relative;
}

.content-pink p {
  text-align: left;
  font-size: 24px;
}

.ameneties-content-container {
  width: 60vw;
}

.ameneties-img-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ameneties-img-container img {
  margin-left: 50vw;
  transform: rotateZ(15deg);
  box-shadow: 10px 10px 0px #00FF29;
}

.ameneties-statements {
  margin-left: 15vw;
  width: 400px;
}

.full-height {
  min-height: 100vh;
  overflow: hidden;
}

.tile-button {
  position: absolute;
  bottom: 0;

  outline: inherit;
  box-shadow: 10px 10px 0px #FFD9EC;

  transform: translateY(50%);
}

.color-accent {
  color: #FAE3F1;
}

.button:hover {
  box-shadow: 0px 0px 0px #C72A7B;
  background-color: #C72A7B;
  color: #00FF29;
}

.tiles-container {
  display: inline-flex;
  white-space: pre-wrap;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;
  flex: 1;
}

.tile {
  margin-top: 80px;
  position: relative;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  min-height: 440px;
  border: 10px solid #C72A7B;
  min-width: 300px;
}

.tile-content {
  padding: 20px;
  text-align: left;
  line-height: 1.5em;
  color: #C72A7B;
  font-weight: 400;
}

.logo-container {
  /* position: absolute;
  text-align: center;
  margin: 0 auto;
  top: 10%; */
}

@media screen and (max-width: 640px) {
  .logo-container {
    margin-top: 100px;
  }

  .button {
    margin-top: -100px;
  }
}

.cruise-logo {
  max-width: 640px;
  width: 70vw;
}

.video-container {
  background-color: black;
  height: 100vh;
}

.video-container video {
  width: 100%;
  height: 100%;
}

.title {
  font-family: "Inter";
  font-weight: 800;
  font-style: italic;
  font-size: 128px;
  color: #00FF29;

  stroke: 7px black;
  /* text-shadow: 0 0 0px 10px black; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
