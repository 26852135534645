.example-container {
    width: 100%;
    min-height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -60px;
}

.slide {
    position: absolute;
    height: 300px;
    width: 100%;
}

@media screen and (max-width: 640px) {
    .example-container {
        height: auto;
        min-height: 200px;
        margin-top: 0;
    }
    .slide  {
        height: 200px;
    }
}
